import { publish, subscribe } from '@rei/event-manager';
import jsCookie from 'js-cookie';
import { getStoreHoursAndAvailability } from '@rei/my-store';

const REI_SESSION_USER_CHANGED = 'rei_session_user_changed';

const some = (array, predicate) => {
  if (!array) return false;

  for (let i = 0; i < array.length; i += 1) {
    if (predicate(array[i])) return true;
  }

  return false;
};

const STORE = 'myStoreId';
const STORE_SET = 'myStoreIdSet';
const storeCookieOpts = { expires: 365 };
export const setCookieStore = (userStore) => {
  if (userStore) {
    jsCookie.set(STORE, userStore.id, storeCookieOpts);
    jsCookie.set(STORE_SET, userStore.lastSet, storeCookieOpts);
  } else {
    jsCookie.remove(STORE);
    jsCookie.remove(STORE_SET);
  }
};

export const getCookieStore = () => {
  const cookieStoreId = jsCookie.get(STORE);
  const cookieStoreSet = jsCookie.get(STORE_SET);
  return (
    (cookieStoreId && cookieStoreSet && { id: cookieStoreId, lastSet: cookieStoreSet }) || null
  );
};

export const fetchStoreDetails = async (storeId) => {
  const res = await fetch(`/retail-stores/stores/${storeId}`);
  if (!res.ok) {
    throw res;
  }
  const json = await res.json();
  return json;
};

const setStoreDetails = async (storeId, isSuggestion = false) => {
  if (storeId) {
    await fetchStoreDetails(storeId)
      .then(async (store) => {
        const newFields = await getStoreHoursAndAvailability(store);
        const storeDetails = {
          ...store,
          ...newFields,
          isSuggestion,
        };

        if (storeDetails) {
          localStorage.setItem(
            'preferredStore',
            JSON.stringify({
              storeNumber: storeDetails.storeNumber,
              storeDisplayName: storeDetails.storeDisplayName,
              name: `${storeDetails.storeName}-${storeDetails.storeNumber}`,
              storeName: storeDetails.storeName,
              address1: storeDetails.address1,
              address2: storeDetails.address2,
              address3: storeDetails.address3,
              city: storeDetails.city,
              distance: storeDetails.distance,
              enabledForCurbside: storeDetails.enabledForCurbside,
              enabledForInStore: storeDetails.enabledForInStore,
              enabledForBOPUS: storeDetails.enabledForBOPUS,
              hasBikeShop: storeDetails.hasBikeShop,
              hasBikeDepartment: storeDetails.hasBikeDepartment,
              hasPersonalOutfitting: storeDetails.hasPersonalOutfitting,
              hasSnowDepartment: storeDetails.hasSnowDepartment,
              hasRentals: storeDetails.hasRentals,
              hasSkiShop: storeDetails.hasSkiShop,
              hasTradeIn: storeDetails.hasTradeIn,
              hasUsedGear: storeDetails.hasUsedGear,
              inStoreAndCurbsideAvailable: storeDetails.inStoreAndCurbsideAvailable,
              phone: storeDetails.phone,
              state: storeDetails.state,
              storePageUrl: storeDetails.storePageUrl,
              temporarilyClosed: storeDetails.temporarilyClosed,
              permanentlyClosed: storeDetails.permanentlyClosed,
              zip: storeDetails.zip,
              storeType: storeDetails.storeType,
              availability: storeDetails.availability,
              hours: storeDetails.hours,
              timeZoneId: storeDetails.timeZoneId,
              isSuggestion: storeDetails.isSuggestion || false,
              latitude: storeDetails.latitude,
              longitude: storeDetails.longitude,
            }),
          );
        }
      })
      .catch((error) => error);
  }
};

export const patchPreferredStore = async (storeId) => {
  if (!storeId) {
    return null;
  }
  const res = await fetch('/rest/user/preferences', {
    method: 'PUT',
    headers: {
      Accept: 'application/json;v=2',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id: storeId }),
    credentials: 'same-origin',
  });

  if (!res.ok) throw new Error();
  return res.json();
};

const nullUser = {
  accountId: null,
  isLoggedIn: false,
  addresses: [],
  membership: null,
  contact: null,
  preferredStore: null,
};

const fetchUser = async () => {
  const res = await fetch('/rest/user/account', { credentials: 'same-origin' });

  const json = await res.json();
  if (res.ok) {
    return {
      isLoggedIn: true,
      ...json,
      membership: json.membership || null,
    };
  }

  const notLoggedInCode = {
    400: 1424,
    401: 1402,
  }[res.status];

  if (notLoggedInCode && some(json?.error, (e) => e.code === notLoggedInCode)) {
    return nullUser;
  }

  throw new Error();
};

const shouldPatchStore = (user) => {
  const cookieStore = getCookieStore();
  const preferredStoreLastSet = Number(user.preferredStore?.lastSet || 0);
  const preferredStoreId = user.preferredStore?.id;
  const cookieStoreLastSet = Number(cookieStore?.lastSet || 0);
  const cookieStoreId = cookieStore?.id;

  if (
    user?.isLoggedIn &&
    preferredStoreId !== cookieStoreId &&
    preferredStoreLastSet < cookieStoreLastSet
  ) {
    return true;
  }
  return false;
};

// There's a possibility that an application can call getUser before getUser
// is loaded onto the window. Applications can use addChangeListener
// to subscribe to any updates to the user, and they will get notified
// when a change has occurred.
const addChangeListener = (fn) => subscribe(REI_SESSION_USER_CHANGED, (e) => fn(e.detail.user));

export default class UserService {
  constructor() {
    this.getUser = this.getUser.bind(this);
    this.setPreferredStore = this.setPreferredStore.bind(this);
    this.getPreferredStore = this.getPreferredStore.bind(this);
    this.emitChange = this.emitChange.bind(this);
    if (window && !window.reiSession) {
      window.reiSession = {
        getUser: this.getUser,
        setPreferredStore: this.setPreferredStore,
        getPreferredStore: this.getPreferredStore,
        emitUserChangeEvent: this.emitChange,
        addChangeListener,
      };
    }
    this.userPromise = null;
    this.preferredStoreDetails = null;
  }

  getUser() {
    this.userPromise =
      this.userPromise ||
      fetchUser()
        .then(async (user) => {
          const userStore = user.preferredStore;
          const cookieStore = getCookieStore();
          const storeNeedsUpdate = shouldPatchStore(user);

          let preferredStore = cookieStore;

          if (user?.isLoggedIn) {
            const isSuggestion = !userStore;
            if (storeNeedsUpdate && cookieStore.id) {
              preferredStore = await patchPreferredStore(cookieStore.id);
            } else {
              preferredStore = userStore;
            }
            setCookieStore(preferredStore);
            await setStoreDetails(preferredStore?.id, isSuggestion);
          }
          return { ...user, preferredStore };
        })
        .catch((error) => {
          // Logging the error for debugging
          console.error('failed to get User! ', error);
          return null;
        });

    return this.userPromise;
  }

  async emitChange(user) {
    this.userPromise = Promise.resolve(this.userPromise)
      .catch(() => {})
      .then(() => {
        publish(REI_SESSION_USER_CHANGED, {
          detail: {
            user,
          },
        });
        return user;
      });

    await this.userPromise;
  }

  async setPreferredStore(storeId, isSuggestion) {
    const user = await window.reiSession.getUser();
    // If store is somehow out of sync with persistent data...
    const storeNeedsUpdate = storeId !== user?.preferredStore?.id || shouldPatchStore(user);
    const preferredStore =
      user?.isLoggedIn && storeNeedsUpdate && storeId
        ? await patchPreferredStore(storeId)
        : storeId && { id: storeId, lastSet: +new Date() };

    setCookieStore(preferredStore);

    const existingPreferredStore = await this.getPreferredStore();
    // These two values are not the same type (number vs. string representation of number)
    // so using != rather than !==
    const preferredStoreChanged = existingPreferredStore?.storeNumber != preferredStore?.id;

    if (storeId) {
      const preferredStoreId = preferredStore ? preferredStore.id : storeId;
      if (preferredStoreChanged) {
        // Get store details for localStorage
        await setStoreDetails(preferredStoreId, isSuggestion);
      }
    } else {
      // If no storeID, we remove localStorage key/value
      localStorage.removeItem('preferredStore');
    }

    const newUser = {
      ...user,
      preferredStore,
    };

    if (preferredStoreChanged) {
      this.emitChange(newUser);
    }
  }

  async getPreferredStore() {
    try {
      this.preferredStoreDetails = localStorage.getItem('preferredStore');
      if (this.preferredStoreDetails) {
        return JSON.parse(this.preferredStoreDetails);
      }
    } catch (error) {
      localStorage.removeItem('preferredStore');
    }
    return null;
  }
}

new UserService();
