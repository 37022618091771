export const getAdobeTargetJsonOffer = (mbox) =>
  new Promise((resolve, reject) => {
    window?.adobe?.target?.getOffer({
      mbox,
      params: null,
      success: (offer) => {
        const jsonOffers = offer.filter((o) => o.action === 'setJson');
        resolve(jsonOffers?.[0]?.content);
      },
      error: () => {
        reject(new Error('Could not retrieve Adobe Target JSON Offer'));
      },
    });
  });

export default getAdobeTargetJsonOffer;
