<template>
  <button
    id="my-store-nav-button"
    class="my-store-nav-button icon-button"
    data-ui="site-header-action-local"
  >
    <IconXLg class="gnav-icon my-store-nav-button--icon close-icon" />
    <IconLocationPinStroke class="gnav-icon my-store-nav-button--icon location-icon" />
    <div class="my-store-nav-button__text-content">
      <cdr-text tag="span" data-visible="desktop">
        {{ myReiText }}
        <span v-if="availability" :class="availabilityClasses">
          <template v-if="preferredStore.temporarilyClosed || availability.temporarilyClosed">
            Temporarily Closed
          </template>
          <template v-else-if="availability.open && availability.closesAt">
            {{ availability.closesAt.replace('today', '').replace('until', 'til') }}
          </template>
          <template v-else-if="!availability.open && availability.opensAt">
            {{ availability.opensAt }}
          </template>
        </span>
      </cdr-text>
      <cdr-text tag="span" data-visible="desktop">
        {{ storeName }}
      </cdr-text>
    </div>
  </button>
</template>

<script>
import { CdrText, IconLocationPinStroke, IconXLg } from '@rei/cedar';
import { getPreferredStore } from '@rei/session';

export default {
  name: 'NavButton',
  components: {
    CdrText,
    IconLocationPinStroke,
    IconXLg,
  },
  inject: ['globalEmitter'],
  data() {
    return {
      preferredStore: null,
    };
  },
  computed: {
    availability() {
      return this.preferredStore && this.preferredStore.availability
        ? this.preferredStore.availability
        : null;
    },
    myReiText() {
      if (this.preferredStore && this.preferredStore.isSuggestion) {
        return 'Near you';
      }
      return 'My REI';
    },
    storeName() {
      if (this.preferredStore && this.preferredStore.storeDisplayName) {
        return this.preferredStore.storeDisplayName;
      }
      return 'Find your store';
    },
    availabilityClasses() {
      if (!this.preferredStore) return '';

      const { temporarilyClosed, availability = {} } = this.preferredStore;

      return {
        'my-store-nav-button__store--closed':
          temporarilyClosed || availability.temporarilyClosed || !availability.open,
        'my-store-nav-button__store--opened':
          !temporarilyClosed && !availability.temporarilyClosed && availability.open,
      };
    },
  },
  async mounted() {
    this.preferredStore = await getPreferredStore();
    this.globalEmitter.on('update-store', this.handleUpdateStore);
    this.globalEmitter.on('suggested-store', this.handleUpdateStore);
  },
  beforeUnmount() {
    this.globalEmitter.off('update-store', this.handleUpdateStore);
    this.globalEmitter.off('suggested-store', this.handleUpdateStore);
  },
  methods: {
    handleUpdateStore(newStore) {
      this.preferredStore = newStore;
    },
  },
};
</script>

<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";

.my-store-nav-button {
  & &--icon {
    fill: #292929;

    @include cdr-md-mq-up {
      fill: #616161;
    }
  }

  .sr-only {
    @include cdr-display-sr-only;
  }

  .close-icon {
    display: none;
  }

  &[aria-expanded='true'] {
    .location-icon {
      display: none;

      @include cdr-md-mq-up {
        display: block;
      }
    }

    .close-icon {
      display: block;
      @include cdr-md-mq-up {
        display: none;
      }
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;

    & > [data-visible='desktop'] {
      margin-left: $cdr-space-quarter-x;
    }

    & > span:first-of-type {
      @include cdr-text-utility-sans-100;
    }

    & > span:last-of-type {
      color: #292929;
      text-decoration: underline;
      @include cdr-text-utility-sans-200;
    }
  }

  &__store--opened,
  &__store--closed {
    margin-left: $cdr-space-quarter-x;
  }

  &__store--opened {
    color: #367c3c;
  }

  &__store--closed {
    color: $cdr-color-text-secondary;
  }
}
</style>
