class RewardsApi {
  constructor() {
    this.rewardsService = '/account-site/rewards-lookup';
  }

  async getMemberRewards() {
    try {
      const response = await fetch(`${this.rewardsService}`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        return null;
      }
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  }
}

export default new RewardsApi();
