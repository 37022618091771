import { createApp } from 'vue';
import mitt from 'mitt';
import NavButton from './components/NavButton.vue';
import MyStoreBackdrop from './components/MyStoreBackdrop.vue';
import MyStoreComponent from './components/MyStoreComponent.vue';

import './account';

const gNav = document.querySelector('.gnav');
const isWhiteNav = gNav !== null;

let isMyStoreMounted = false;
const globalEmitter = mitt();

function addBackdrop() {
  const secondaryNav = document.querySelector('[data-js="secondary-nav"]');
  const backdrop = document.createElement('div');
  backdrop.setAttribute('id', 'my-store-backdrop');
  secondaryNav.appendChild(backdrop);

  createApp(MyStoreBackdrop).mount('#my-store-backdrop');
}

function addNavButton() {
  const defaultMyStoreLink = document.querySelector('[data-ui="site-header-action-local"]');

  if (defaultMyStoreLink) {
    const defaultMyStoreLinkContainer = defaultMyStoreLink.parentNode;
    defaultMyStoreLinkContainer.replaceChildren();

    const navButtonApp = createApp(NavButton);
    // needs to hook into shared emitter to display selected store
    navButtonApp.provide('globalEmitter', globalEmitter).mount(defaultMyStoreLinkContainer);
  }
}

if (isWhiteNav) {
  addBackdrop();
  addNavButton();
}

const myStoreToggleButton = document.querySelector("[data-ui='site-header-action-local']");
const navbarWrapper = document.querySelector("[data-ui='site-navigation']");

// Mount mystore and set event listeners, this function will only be executed
// if my storeToggleButton is hover or focused
// Otherwise there is no need to mount component
const mountMyStore = () => {
  sessionStorage.setItem('myStoreOpen', 'false');
  isMyStoreMounted = true;
  if (myStoreToggleButton) {
    // Insert <li> element next to storeToggle button to render MyStore component
    myStoreToggleButton.parentNode.insertAdjacentHTML(
      'afterEnd',
      `<li class="stores-dropdown ${
        isWhiteNav && 'stores-dropdown--white-nav'
      }"><div id="my-store-placement"></div></li>`,
    );

    const myStoreApp = createApp(MyStoreComponent);
    myStoreApp.provide('globalEmitter', globalEmitter);

    myStoreApp.mount('#my-store-placement');
  }
};

if (myStoreToggleButton && navbarWrapper) {
  if (isWhiteNav) {
    myStoreToggleButton.parentNode.removeAttribute('data-visible');
  }

  ['focus', 'mouseenter'].forEach((eventName) =>
    myStoreToggleButton.addEventListener(
      eventName,
      () => {
        if (!isMyStoreMounted) {
          mountMyStore();
        }
      },
      {
        once: true,
      },
    ),
  );

  window.addEventListener('load', () => {
    if (!isMyStoreMounted) {
      mountMyStore();
    }
  });
}
