<script setup>
import { AccountFlyout } from '@rei/account-flyout';
import { addChangeListener } from '@rei/session';
import mitt from 'mitt';
import { reactive, ref, computed, onBeforeMount, onBeforeUnmount } from 'vue';

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  sidebarCobrandExperience: {
    type: String,
    default: 'control',
  },
});

const accountEventBus = mitt();
const gNav = ref(null);
const accountToggleButton = ref(null);

const isWhiteNav = computed(() => gNav.value !== null);
const open = ref(false);

const state = reactive({
  reactiveUser: props.user,
  accountListener: null,
});

const addUserListener = () => {
  state.accountListener = addChangeListener((user) => {
    state.reactiveUser = user;
  });
};

const destroyUserListener = () => {
  if (state.accountListener) {
    state.accountListener.unsubscribe();
    state.accountListener = null;
  }
};

const toggle = () => {
  open.value = !open.value;
};

const handleClose = () => {
  toggle();
  if (accountToggleButton.value) {
    accountToggleButton.value.setAttribute('aria-expanded', 'false');
  }

  window?.metrics?.link?.({ linkName: 'login_module:close' });

  if (isWhiteNav.value && gNav.value) {
    gNav.value.setAttribute('data-account', 'false');
    document.body.classList.remove('no-scroll');
  }
};

const toggleButtonHandler = (event) => {
  event.preventDefault();
  event.stopPropagation();

  const { clientWidth } = window.document.body;

  if (clientWidth < 992) {
    window.location.href = '/YourAccountInfoInView';
  } else {
    toggle();
  }

  document.body.classList.remove('sign-in-open');
  if (accountToggleButton.value) {
    accountToggleButton.value.setAttribute('aria-expanded', open.value ? 'true' : 'false');
  }

  if (isWhiteNav.value && gNav.value) {
    gNav.value.setAttribute('data-account', open.value ? 'true' : 'false');

    if (open.value) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
};

const closed = () => {
  accountEventBus.emit('closed');
};


onBeforeMount(() => {
  gNav.value = document.querySelector('.gnav');
  accountToggleButton.value = document.querySelector(
    "[data-ui='site-header-action-local-account']",
  );

  addUserListener();

  accountEventBus.on('closed', handleClose);
  if (accountToggleButton.value) {
    accountToggleButton.value.setAttribute('aria-expanded', 'false');
    accountToggleButton.value.setAttribute('aria-controls', 'account-sidebar');
    accountToggleButton.value.addEventListener('click', toggleButtonHandler);
  }
});

onBeforeUnmount(() => {
  destroyUserListener();

  accountEventBus.off('closed', handleClose);
  if (accountToggleButton.value) {
    accountToggleButton.value.removeEventListener('click', toggleButtonHandler);
  }
});
</script>

<template>
  <account-flyout
    :open="open"
    :user="state.reactiveUser"
    :sidebar-cobrand-experience="sidebarCobrandExperience"
    @closed="closed"
  />
</template>

<style>
  .QSIFeedbackButton {
      z-index: 9998 !important;
  }
</style>
