<script setup>
import { inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { MyStore } from '@rei/my-store';

const gNav = document.querySelector('.gnav');
const myStoreToggleButton = document.querySelector("[data-ui='site-header-action-local']");
const navbarWrapper = document.querySelector("[data-ui='site-navigation']");
const storesDropdown = document.querySelector('.stores-dropdown');

const isWhiteNav = ref(gNav !== null);
const open = ref(false);

const emitter = inject('globalEmitter');

function handleClose(escKeyPressed) {
  open.value = false;
  document.querySelector('#my-store-sr-only').textContent = 'Open select my store';
  myStoreToggleButton.setAttribute('aria-expanded', 'false');
  navbarWrapper.style.overflowY = 'initial';
  const isFocusOnBody = document.activeElement.tagName === 'BODY';
  if (isFocusOnBody || escKeyPressed) {
    myStoreToggleButton.focus();
  }

  if (isWhiteNav.value) {
    gNav.setAttribute('data-my-store', 'false');
    document.body.classList.remove('no-scroll');
  }
}

function toggle() {
  open.value = !open.value;
  sessionStorage.setItem('myStoreOpen', open.value);
}

function toggleButtonHandler(event) {
  if (!isWhiteNav.value) {
    event.preventDefault();
    event.stopPropagation();
  }

  const { clientWidth } = window.document.body;

  toggle();
  document.body.classList.remove('sign-in-open');
  document.querySelector('#my-store-sr-only').textContent = `${
    open.value ? 'Close' : 'Open'
  } select my store`;
  myStoreToggleButton.setAttribute('aria-expanded', open.value ? 'true' : 'false');
  navbarWrapper.style.overflowY = open.value && clientWidth < 992 ? 'hidden' : 'initial';
  storesDropdown.style.display = open.value ? 'block' : 'none';

  if (isWhiteNav.value) {
    gNav.setAttribute('data-my-store', open.value ? 'true' : 'false');

    if (open.value) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
}

function clickOutsideHandler(event) {
  // Avoid closing component if user clicked on any of those elements
  const excludeElements = [
    'change-store-button',
    'set-store',
    'store-details-closed',
    'edit-location-link',
    'store-details-closed',
    'site-header-action-local',
  ];

  if (
    !open.value ||
    excludeElements.includes(event.target.getAttribute('data-ui')) ||
    myStoreToggleButton.contains(event.target)
  )
    return;

  if (!storesDropdown.contains(event.target)) {
    emitter.emit('closed');
  }
}

function setStore(store) {
  emitter.emit('update-store', store);
}
function closed() {
  emitter.emit('closed');
}
function onEscape() {
  emitter.emit('closed', 'escape key was pressed');
}
function suggestedStore(store) {
  emitter.emit('suggested-store', { ...store, isSuggestion: true });
}

onMounted(() => {
  emitter.on('closed', (escKey) => handleClose(escKey));
  myStoreToggleButton.setAttribute('aria-expanded', 'false');
  const newSpan = document.createElement('span');
  newSpan.setAttribute('class', 'sr-only');
  newSpan.setAttribute('id', 'my-store-sr-only');
  newSpan.append('Open select my store');
  myStoreToggleButton.append(newSpan);
  myStoreToggleButton.setAttribute('aria-controls', 'my-store-bar');
  myStoreToggleButton.addEventListener('click', toggleButtonHandler);
  window.addEventListener('click', clickOutsideHandler, true);
});

onBeforeUnmount(() => {
  emitter.off('closed', handleClose);

  myStoreToggleButton.removeEventListener('click', toggleButtonHandler);
  window.removeEventListener('click', clickOutsideHandler, true);
});
</script>

<template>
  <MyStore
    :open="open"
    :fetch-suggested-store="isWhiteNav"
    :custom-classes="['my-store-wrapper']"
    :trap-tab-inside-widget="true"
    @set-store="setStore"
    @closed="closed"
    @on-escape="onEscape"
    @suggested-store="suggestedStore"
  />
</template>

<style lang="scss" scoped></style>
