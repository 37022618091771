<template>
  <div class="item-account-link">
    <AccountLink />
    <AccountLink
      link-text="My Account - Sign in or Register"
      is-mobile-only
      mounting-point-selector="[data-js='mobile-nav-login-link']"
    />
  </div>
</template>

<script>
import { AccountLink } from '@rei/account-flyout';

export default {
  name: 'AccountLinkFragment',
  components: {
    AccountLink,
  },
};
</script>

<style>
.item-account-link {
  padding: 8px;
}
</style>
