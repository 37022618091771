<template>
  <div class="loading-fragment" data-ui="login-module-greeting">
    <CdrLink href="/YourAccountInfoInView" class="account-info-link">
      <IconAccountProfile />
      <div class="loading-fragment__skeleton" data-visible="desktop" />
    </CdrLink>
  </div>
</template>

<script>
import { IconAccountProfile, CdrLink } from '@rei/cedar';

export default {
  name: 'LoadingFragment',
  components: {
    IconAccountProfile,
    CdrLink,
  },
};
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";

.loading-fragment {
  display: flex;
  align-items: center;

  .account-info-link {
    display: flex;
    gap: $cdr-space-inset-quarter-x;
  }

  svg {
    fill: #616161;
  }

  &__skeleton {
    background: linear-gradient(90deg, #c0c0c0 0%, #f7f7f7 50%, #c0c0c0 100%);
    background-size: 250% 250%;
    animation: skeleton-loading 2s ease infinite;
    width: 100px;
    height: $cdr-space-inset-three-quarter-x-stretch-top-bottom;
    border-radius: 3rem;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
