import { getPropositions } from '@rei/adobe-target-functions';
import { getAdobeTargetJsonOffer } from './adobe-target-json-offer';

/**
 * Rejects the promise if the Adobe Target takes more than certain seconds to respond
 * @param {number} timeout - The number of milliseconds to wait before rejecting the promise
 * @returns {Promise}
 */
const maxTimeToWaitForAdobeTarget = (timeout) =>
  new Promise((_, reject) => {
    setTimeout(
      () =>
        reject(new Error(`Adobe Target is taking more than ${timeout / 1000} seconds to respond`)),
      timeout,
    );
  });

const mapExperiences = (
  experiences,
  testName,
  options = {
    isAlloy: false,
  },
) => {
  if (experiences?.length) {
    const result = experiences?.reduce((acc, item) => {
      const activity = options.isAlloy ? item?.content[0] : item?.[0];
      if (!activity) return acc;

      acc[activity?.experimentName] = activity?.experience;
      return acc;
    }, {});

    if (testName && !result[testName]) throw new Error(`Experience ${testName} not found`);

    return testName ? result[testName] : result;
  }
  throw new Error('No experiences found');
};

const AdobeTarget = {
  /**
   * Provides an object with Adobe Target activity test name and its experience
   * @param {string} testName -
   * The Adobe Target experience test name to get the experience. EX. APEX123.
   * @returns {Promise<any>} an object with Adobe Target activity test name and its experience Ex: {"APEX123": "control"}
   */
  async get(testName) {
    try {
      if (!testName) {
        throw new Error('No options were provided');
      }

      if (typeof window?.alloy === 'function') {
        const experiences = await Promise.race([
          getPropositions(['__view__']),
          maxTimeToWaitForAdobeTarget(3000),
        ]);

        return mapExperiences(experiences, testName, { isAlloy: true });
      }

      if (window?.adobe?.target) {
        const experiences = await getAdobeTargetJsonOffer('target-global-mbox');
        return mapExperiences(experiences, testName);
      }

      console.error('Both alloy and at.js are undefined');
      throw new Error('Both alloy and at.js are undefined');
    } catch (error) {
      return null;
    }
  },
};

export default AdobeTarget;
